@import "~normalize.css/normalize.css";
@import "partials/_variables.scss";
@import "partials/_accordion.scss";
@import "partials/_slider.scss";
@import "partials/_columns.scss";
@import "partials/_buttons.scss";
@import "partials/_forms.scss";
@import "partials/_messages.scss";
@import "partials/_breakpoints.scss";

@import "partials/_cart.scss";

$max-width: 1440px;
$min-width: 320px;

// Global Styles
*,
*:before,
*:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body,
html {
    font-family: $font;
    position: relative;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

// Error pages
.text-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0.5em;
}

.container {
    padding: 0 40px;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;

    @include md {
        padding: 0 20px;
    }
}

.row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 auto;

    @include md {
        flex-direction: column;
    }
}

nav {
    &.breadcrumbs {
        text-align: left;
        ul {
            display: inline-block;
            li {
                display: inline-block;
                &:not(:last-child)::after {
                    content: ">";
                    display: inline-block;
                }
            }
        }
    }
}
body > header {
    ul {
        li {
            list-style: none;
            display: inline-block;
        }
    }
    // &.user {
    //     .cart {
    //         box-shadow: 5px 5px 5px rgba($off-black, 0.2);
    //         .cart-items {
    //             padding-top: 10px!important;
    //             padding-bottom: 0!important;
    //             .cart-item {
    //                 padding: 0 0;
    //                 .item-details {
    //                     flex: 1 1 auto;
    //                 }
    //                 .item-qty {
    //                     flex: 0 0 auto;
    //                 }
    //                 .item-price {
    //                     flex: 1 1 auto;
    //                     text-align: right;
    //                 }
    //                 .item-remove {
    //                     flex: 0 0 auto;
    //                     margin-right: -10px;
    //                     margin-left: 10px;
    //                 }
    //             }
    //         }
    //         .totals {
    //             font-weight: 600;
    //             padding:  5px 10px 5px 5px;
    //             border-top: 1px dotted $off-black;
    //         }
    //     }
    // }
}

main {
    &.account,
    &.checkout {
        ul.sections {
            justify-content: center;
            li {
                display: block;
                padding: 0 10px;
                a {
                    display: block;
                    border: 1px solid $black;
                    border-radius: 5px;
                    padding: 10px 20px;
                }
                &.current {
                    a {
                        background: $black;
                        color: $white;
                    }
                }
            }
        }
        form {
            max-width: 600px;
            flex: 1 0 auto;
        }
        &.address,
        &.cart {
            form {
                max-width: 980px;
                flex: 1 0 auto;
            }
        }
    }
    &.checkout {
        &.cart {
            .cart-items {
                & > li.cart-item {
                    padding: 5px 10px;
                    input {
                        margin: 0;
                    }
                    .item-image {
                        width: 40px;
                        height: 40px;
                        flex: 0 0 auto;
                    }
                    .item-details {
                        text-align: left;
                        justify-self: flex-start !important;
                        flex: 1 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                    }
                    .item-qty {
                        flex: 0 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                        input {
                            display: inline-block;
                            width: 8rem;
                        }
                    }
                    .item-price {
                        flex: 0 0 auto;
                        text-align: right;
                        max-width: 8rem;
                        padding: 0 10px;
                    }
                    .item-remove {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .totals {
                font-weight: 600;
                padding: 0 20px;
                span {
                    display: inline-block;
                    min-width: 8rem;
                }
            }
        }
        &.shipping,
        &.payment {
            & > .container > .row {
                justify-content: center;
                align-items: flex-start;
            }
            form {
                margin: 0;
                fieldset {
                    &.shipping-methods {
                        padding: 20px;
                        label {
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            input {
                                margin-right: 1em;
                            }
                            span {
                                font-weight: 600;
                                display: inline-block;
                                margin-left: 1em;
                            }
                        }
                    }
                    &.buttons {
                        display: flex;
                        justify-content: center;
                        button,
                        .button {
                            margin: 2em 10px 0;
                        }
                    }
                }
                .payment-method {
                    margin-top: 20px;
                    padding-top: 20px;
                }
                .message {
                    padding: 20px 10px;
                    text-align: left;
                    ul {
                        margin: 1em 2em;
                    }
                }
            }
            .totals {
                border-radius: 5px;
                padding: 40px 40px;
                max-width: 330px;
                margin: 0 10px;
                flex: 1 0 auto;
                text-align: left;
                ul {
                    margin: 1em 0;
                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        padding: 0.5em 5px;
                        &.total {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

main {
    &.products {
        ul.row {
            justify-content: flex-start;
            li.product {
                list-style: none;
                border: 1px solid $black;
                overflow: hidden;
                width: calc(25% - 20px);
                margin: 10px;
                a {
                    &.image {
                        display: block;
                        width: 100%;
                        padding-top: 100%;
                        background-size: contain;
                    }
                }
                div {
                    text-align: left;
                    padding: 20px 40px;
                }
            }
        }
        &.single {
        }
    }
}

// Flatpickr
.flatpickr-wrapper {
    @apply w-full;
}

.flatpickr-calendar.open {
    z-index: 1000 !important;
    filter: none !important;
}

.flatpickr-months {
    @apply mb-2 text-gray-800;
}

.flatpickr-next-month,
.flatpickr-prev-month {
    @apply text-gray-800;

    svg {
        fill: currentColor !important;
    }
}

.flatpickr-current-month span.cur-month:hover,
.numInputWrapper:hover {
    @apply bg-transparent;
}

.flatpickr-day {
    @apply border-0;

    &.today {
        @apply font-semibold;

        &:hover {
            color: inherit;
            @apply bg-gray-400;
        }
    }

    &:hover {
        @apply bg-gray-400;
    }

    &.selected {
        @apply bg-blue-600 text-white;

        &:hover,
        &.today:hover {
            @apply bg-blue-400 text-white;
        }
    }
}

.flatpickr-time {
    border: none !important;

    input,
    .flatpickr-am-pm,
    .flatpickr-time {
        background: none !important;
    }
}

.flatpickr-time input.flatpickr-hour,
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    @apply font-semibold;

    &::selection {
        background: none;
    }
}

// -- NLA Preview

// -- Global
.g-no-underline {
    text-decoration: none;
}

.g-inline-radio {
    display: inline-block;

    label {
        display: inline-block;
        margin-left: 1em;

        input {
            margin-right: 0.5em;
        }
    }
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.half-col {
        @include md {
            flex-direction: row;
        }

        @include xs {
            flex-direction: column;
        }

        fieldset {
            width: 100%;
            max-width: 50%;

            @include xs {
                max-width: 100%;
            }
        }
    }
}

.radioset {
    label {
        &:last-of-type {
            padding-top: 0;
        }
    }
}

span.tip {
    float: left;
    display: block;
    width: 100%;
    margin: 0.5em 0 1em;
    font-size: 85%;
    text-align: left;
}

// -- Header
header {
    position: relative;
    z-index: 2;

    .desktop-menu {
        width: 100%;

        .row {
            @extend .container;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2em 40px;

            @include md {
                flex-direction: row;
            }

            @include xs {
                padding: 1em 20px;
            }
        }

        .column.left {
            display: flex;
            justify-content: flex-start;
        }

        .column.right {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            ul {
                li {
                    margin-left: 1em;

                    &:first-child {
                        margin-left: 0;
                    }

                    a {
                        color: $green1;
                        font-size: 1rem;
                        text-decoration: none;
                        padding-right: 1em;
                        border-right: 1px solid $green1;
                    }
                }
            }
        }

        .logo {
            width: auto;
            max-width: 70px;
        }
    }
}

// -- Mobile menu

.desktop-menu {
    display: block;

    @include md {
        display: none;
    }
}

.mobile-menu {
    display: none;
    @extend .container;

    @include md {
        position: relative;
        display: block;
        z-index: 101;
        padding: 1em 20px;
    }

    .row {
        align-items: center;

        @include md {
            flex-direction: row;
        }
    }

    .logo {
        width: 3.5em;
    }

    .mobile-menu-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5em;

        .column.right {
            display: flex;
            flex-direction: row;
        }
    }
}

.burger-menu {
    display: none;

    @include lg {
        width: 30px;
        height: 30px;
        display: block;
        margin-left: 1.5em;
        padding: 0;
        border-bottom: 0 none;
        position: relative;
        right: 0;
        cursor: pointer;
        z-index: 101;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.7;
        }

        &.active {
            .top {
                background: $green4;
                transform: translateY(11px) translateX(0) rotate(45deg);
            }

            .middle {
                opacity: 0;
            }

            .bottom {
                background: $green4;
                transform: translateY(-9px) translateX(0) rotate(-45deg);
            }
        }

        span {
            background: $green1;
            border: none;
            height: 2px;
            width: 100%;
            position: absolute;
            top: 4px;
            left: 0;
            transition: all 0.35s ease;
            cursor: pointer;
            z-index: 101;

            &:nth-of-type(2) {
                top: 14px;
            }

            &:nth-of-type(3) {
                top: 24px;
            }
        }
    }
}

.overlay {
    position: fixed;
    background: $black;
    top: 0;
    left: 0;
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    // overflow: hidden;
    z-index: 100;

    &.open {
        opacity: 0.98;
        visibility: visible;
        height: 100%;

        li {
            animation: fadeInRight 0.5s ease forwards;
            animation-delay: 0.35s;

            &:nth-of-type(2) {
                animation-delay: 0.4s;
            }

            &:nth-of-type(3) {
                animation-delay: 0.45s;
            }

            &:nth-of-type(4) {
                animation-delay: 0.5s;
            }

            &:nth-of-type(5) {
                animation-delay: 0.55s;
            }

            &:nth-of-type(6) {
                animation-delay: 0.6s;
            }

            &:nth-of-type(7) {
                animation-delay: 0.65s;
            }

            &:nth-of-type(8) {
                animation-delay: 0.7s;
            }

            &:nth-of-type(9) {
                animation-delay: 0.75s;
            }

            &:nth-of-type(10) {
                animation-delay: 0.8s;
            }

            &:nth-of-type(11) {
                animation-delay: 0.85s;
            }

            &:nth-of-type(12) {
                animation-delay: 0.9s;
            }
        }
    }
    @include md {
        opacity: 0;
        visibility: hidden;
    }
}

.overlay-menu {
    display: block;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    padding: 2em;

    ul {
        display: flex;
        flex-direction: column;
        width: 100%;

        li {
            padding: 1em;

            a {
                color: $white;
                font-family: $font;
                font-size: 1.125rem;
                text-decoration: none;
            }
        }
    }
}

// -- Footer

footer {
    .footer-top {
        padding: 2em 0 1em;
        color: $white;
        background-color: $green3;

        .row {
            align-items: center;

            @include md {
                align-items: flex-start;
            }
        }

        .column.left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include md {
                width: 100%;
            }

            h3 {
                font-size: 1.75rem;
                margin-bottom: 0.5em;
            }

            a {
                color: $white;
            }

            p {
                margin-bottom: 0.75em;
            }
        }

        .column.right {
            .form-inline {
                display: flex;
                align-items: center;
                margin-top: 1em;
            }
            @include md {
                margin-top: 2em;
            }
        }
    }

    .footer-bottom {
        padding: 1em 0;
        color: $white;
        background-color: $green2;

        .row {
            @include md {
                align-items: flex-start;
            }
        }

        small {
            @include md {
                margin: 0.25em 0;
            }
        }

        a {
            color: $white;
        }
    }
}

// -- Page header

#default-page-header {
    width: 100%;
    min-height: 120px;
    background-color: $green4;

    .container {
        padding: 2em;
    }

    h1 {
        color: $green1;
        font-size: 2.5rem;

        @include lg {
            font-size: 2rem;
        }

        @include md {
            font-size: 1.75rem;
        }

        @include xs {
            font-size: 1.5rem;
        }
    }
}

// -- Submission page

#cms-content-form {
    padding: 40px;
    align-items: flex-start;

    .column.left {
        width: 25%;

        @include lg {
            width: 35%;
        }

        @include md {
            width: 100%;
            margin-bottom: 2em;
        }
    }

    .column.right {
        width: 75%;
        padding-left: 2em;

        @include lg {
            width: 65%;
        }

        @include md {
            width: 100%;
            padding-left: 0;
        }
    }

    .side-nav {
        ul {
            li {
                padding: 1em 2em;
                margin-bottom: 0.25em;
                list-style-type: none;
                background-color: $green5;

                &.current {
                    background-color: $green3;

                    a {
                        color: $white;
                    }
                }

                a {
                    color: $green1;
                    text-decoration: none;
                    font-weight: 600;
                    line-height: 1.5;
                    letter-spacing: 0.5px;
                }
            }
        }
    }

    .content-block {
        margin-bottom: 1em;
        padding-bottom: 1em;

        .text {
            h2 {
                font-size: 1.75rem;
                line-height: 1.3;
                margin-bottom: 1em;
                border-bottom: 1px solid $green3;
                padding-bottom: 0.5rem;
            }

            p {
                line-height: 1.3;
            }

            ul {
                padding-left: 1em;

                li {
                    margin: 10px 0;
                }
            }

            ol {
                padding-left: 1em;

                li {
                    margin: 1em 0;
                }
            }

            .fr-file {
                margin-bottom: 1.5em;

                &:before {
                    margin-right: 1em;
                }
            }
        }
    }

    .submission-form-section {
        h2 {
            font-size: 1.75rem;
            line-height: 1.3;
            margin-bottom: 1em;
        }

        h4 {
            text-transform: uppercase;
            margin-bottom: 1em;
        }

        #submission-info {
            ul {
                margin-left: 1em;

                li {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    @include xs {
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 0.5em;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        top: 10px;
                        margin: 8px 0 0 -12px;
                        vertical-align: middle;
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        background: #000;
                        border-radius: 100%;

                        @include xs {
                            top: 0px;
                            left: -3px;
                        }
                    }
                }
            }
        }

        #submission-questions {
            margin-top: 1em;
            border-top: 1px solid $green3;
            padding-top: 1em;

            p {
                margin-bottom: 0.75em;
            }
        }
    }
}

// -- Published Submission

.submission-published-section {
    margin-top: 2em;
    border-top: 1px solid $green3;
    padding-top: 2em;

    h4 {
        text-transform: uppercase;
        margin-bottom: 2em;
    }

    ul.list {
        padding-left: 1em;

        li {
            margin: 1em 0;

            a {
                color: $green1;
            }
        }
    }
}

// -- Announcement box

#announcement{
    background-color: $green5;
    padding: 2em;
    margin-bottom: 1em;
    text-align: center;
    border: 2px solid $green3;

    .text-content{
        strong{
            font-size: 1.25rem;
            margin-bottom: 0.25em;
            display: block;
        }
    }
}

// -- Page 404 page

body, html{
    position: relative;
    height: 100vh;
    width: 100%;
}

.content {
    overflow: auto;
    margin: 0 auto;
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    
    .text {
        text-align: center;
        padding: 0 1em;
    }

    h2 {
        font: bold 18px/1.4 Arial, Helvetica, sans-serif;
        margin: 2em 0;
        text-transform: none;
    }

    h2 span {
        color: #fc4328;
    }

    img {
        display: block;
        margin: 0 auto;
        height: 200px;
        width: 209.5px;
    }
}


