button, .button {
    display: table;
    margin: 2em 0 1em;
    padding: 0.75rem 1.5rem;
    transition: .2s ease-in-out;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    letter-spacing: 1.2px;
    line-height: inherit;
    text-decoration: none;
    border: none;
    color: $white;

    &:hover {
        transition: .2s ease-in-out;
    }

    &:focus {
        outline: 0;
    }

    &.primary {
        background: $green2;
        color: $white;

        &:hover {
            background: $green5;
            color: $green1;
        }
    }

    &.border {
        background: transparent;
        box-shadow: inset 0 0 0 1px $primary;
        border: none;
        color: $primary;

        &:hover {
            background: $primary;
            color: $white;
        }
    }
}
