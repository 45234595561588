// Colours
$white: #ffffff;
$black: #000000;
$light-grey: #dfdfdf;
$dark-grey: #212121;

$primary: #01243a;
$secondary: #1c4c56;

$positive: #0ab04b;
$negative: #e64747;

$label: #1c4c56;
$input-bg: #ffffff;
$input-border: #dfdfdf;
$input-font: #212121;

$green1: #102515;
$green2: #004016;
$green3: #428D52;
$green4: #9FD18B;
$green5: #D4E7C8;


// Spacing
$btn-padding: 1rem 1.5rem;

// Typography
$font: Arial, Helvetica, sans-serif;
